import "#css/app-base.css";
import "#css/app-components.css";
import "#css/app-utilities.css";

import VueGlide from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";
import * as VueGoogleMaps from "vue2-google-maps";
import ScrollReveal from "vue-scroll-reveal";
import MarqueeText from "vue-marquee-text-component";

const main = async () => {
  // Async load dependencies
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ "vue");
  const { default: axios } = await import(
    /* webpackChunkName: "axios" */ "axios"
  );

  Vue.use(VueGlide);
  Vue.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyCHwVn8oGFkwsSMV-Bsa7Yb3dpPP1CVqZw",
    },
  });
  Vue.use(ScrollReveal);
  Vue.component("marquee-text", MarqueeText);

  return {
    Vue,
    axios,
  };
};

// Execute async function
main().then((components) => {
  const { Vue, axios } = components;

  // Add a global instance of axios to Vue
  axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  Vue.prototype.$axios = axios;

  /* eslint-disable no-unused-vars */
  const vm = new Vue({
    el: "#app",
    components: {
      Carousel: () =>
        import(/* webpackChunkName: "Carousel" */ "#vue/Carousel.vue"),
      CustomCursor: () =>
        import(/* webpackChunkName: "CustomCursor" */ "#vue/CustomCursor.vue"),
      ScrollLink: () =>
        import(/* webpackChunkName: "ScrollLink" */ "#vue/ScrollLink.vue"),
      CompactMenu: () =>
        import(/* webpackChunkName: "CompactMenu" */ "#vue/CompactMenu.vue"),
      Tabs: () => import(/* webpackChunkName: "Tabs" */ "#vue/Tabs.vue"),
      GoogleMap: () =>
        import(/* webpackChunkName: "GoogleMap" */ "#vue/GoogleMap.vue"),
      HomeHero: () =>
        import(/* webpackChunkName: "HomeHero" */ "#vue/HomeHero.vue"),
    },
    data() {
      return {
        bannerHidden: false,
        compactMenuIsVisible: false,
        siteMaskIsVisible: false,
      };
    },
    mounted() {
      window.app.emit("vue-mounted");
      window.addEventListener("resize", this.resizeEvent);
      if (
        document.cookie
          .split(";")
          .some((item) => item.includes("bannerHidden=true"))
      ) {
        this.bannerHidden = true;
      }
    },
    methods: {
      hideBanner() {
        this.bannerHidden = true;
        document.cookie = "bannerHidden=true; expires=0; path=/";
      },
      toggleCompactMenu() {
        if (this.compactMenuIsVisible) {
          this.compactMenuIsVisible = false;
        } else {
          this.compactMenuIsVisible = true;
        }
      },
      showSitemask() {
        this.siteMaskIsVisible = true;
      },
      hideSitemask(e) {
        this.siteMaskIsVisible = false;
        // console.log(e, e.target);
      },
      resizeEvent() {
        if (window.innerWidth >= 1024) {
          this.compactMenuIsVisible = false;
        }
      },
    },
  });
  /* eslint-enable no-unused-vars */
});
